import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Card, CardContent } from "./ui/card";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from "./ui/dialog";
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetDescription } from "./ui/sheet";
import { useMediaQuery } from '../hooks/useMediaQuery';
import { formatDistanceToNow, format } from 'date-fns';
import { Progress } from "./ui/progress";
import { Sparkles, Trash2, X } from 'lucide-react';
import ReactMarkdown from 'react-markdown';
import TwitterHandle from './TwitterHandle';
import { Button } from "./ui/button";
import { useProfile } from '../contexts/ProfileContext';
import { useToast } from "./ui/use-toast";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "./ui/table";

const formatNumber = (num) => new Intl.NumberFormat().format(num);

const calculateLevel = (tweets, insights) => {
  const tweetScore = tweets.length * 0.5;
  const insightScore = insights.length * 2;
  const engagementScore = tweets.reduce((sum, tweet) => sum + ((tweet.likes || 0) + (tweet.retweets || 0)) * 0.01, 0);
  const totalScore = tweetScore + insightScore + engagementScore;
  return Math.min(Math.floor(totalScore / 100) + 1, 10); // Allows levels up to 10
};

const ProfileCard = React.memo(({ profileId, profile, tweets, insights, isExample }) => {
  const { profileData, fetchProfileData, removeProfile } = useProfile();
  const [profileAnalysis, setProfileAnalysis] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const isMobile = useMediaQuery('(max-width: 640px)');
  const { toast } = useToast();
  const [error, setError] = useState(null);

  const level = isExample ? profile.level : calculateLevel(tweets || [], insights || []);
  const progress = useMemo(() => {
    const totalScore = (tweets?.length || 0) * 0.5 + 
                       (insights?.length || 0) * 2 + 
                       (tweets || []).reduce((sum, tweet) => sum + ((tweet.likes || 0) + (tweet.retweets || 0)) * 0.01, 0);
    return Math.min((totalScore % 100) / 100 * 100, 100);
  }, [tweets, insights]);

  const fetchProfileAnalysis = useCallback(async () => {
    if (isLoading || profileAnalysis) return;
    setIsLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/profile-analysis/${profileId}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setProfileAnalysis(data.analysis);
    } catch (error) {
      console.error('Error fetching profile analysis:', error);
      setError(error.message || "Failed to fetch profile analysis. Please try again.");
      toast({
        title: "Error",
        description: error.message || "Failed to fetch profile analysis. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  }, [profileId, isLoading, profileAnalysis, toast]);

  useEffect(() => {
    if (isDialogOpen && !profileAnalysis && !isLoading && !error) {
      fetchProfileAnalysis();
    }
  }, [isDialogOpen, profileAnalysis, isLoading, error, fetchProfileAnalysis]);

  const handleCardClick = () => {
    if (!isExample) {
      setIsDialogOpen(true);
    } else {
      // Show a toast or some other notification for example cards
      toast({
        title: "Example Persona",
        description: "Sign in to view and manage real Personas!",
        duration: 3000,
      });
    }
  };

  const handleDialogClose = () => setIsDialogOpen(false);

  const handleDeleteProfile = async () => {
    setIsDeleting(true);
    // Optimistic update
    removeProfile(profileId);
    handleDialogClose();

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/delete-profile/${profileId}`, {
        method: 'DELETE',
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      toast({
        title: "Persona Deleted",
        description: "The Persona has been deleted.",
      });
      // Refresh the profile data to ensure our UI is up-to-date
      await fetchProfileData();
    } catch (error) {
      console.error('Error deleting profile:', error);
      // Revert the optimistic update and refresh data
      await fetchProfileData();
      toast({
        title: "Error",
        description: "Failed to delete Persona. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsDeleting(false);
    }
  };

  const DialogOrSheet = isMobile ? Sheet : Dialog;
  const DialogOrSheetContent = isMobile ? SheetContent : DialogContent;
  const DialogOrSheetHeader = isMobile ? SheetHeader : DialogHeader;
  const DialogOrSheetTitle = isMobile ? SheetTitle : DialogTitle;
  const DialogOrSheetDescription = isMobile ? SheetDescription : DialogDescription;

  const renderPromptTable = () => {
    if (!profileData || !profileData.reminders) return null;

    return (
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Prompt</TableHead>
            <TableHead>Truffles</TableHead>
            <TableHead>Insights</TableHead>
            <TableHead>Duration</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {profileData.reminders.map((reminder, index) => (
            <TableRow key={index}>
              <TableCell>{reminder.reformulatedPrompt}</TableCell>
              <TableCell>{reminder.triggerEvents.length}</TableCell>
              <TableCell>{reminder.insights.length}</TableCell>
              <TableCell>
                {format(new Date(reminder.createdAt), 'MMM d, yyyy')} - 
                {reminder.deletedAt 
                  ? format(new Date(reminder.deletedAt), 'MMM d, yyyy')
                  : 'Present'}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  const renderActionReport = () => {
    if (!profileData || !profileData.reminders) return null;

    const allPrompts = profileData.reminders.map(r => r.reformulatedPrompt).join(' ');
    const allTweets = profileData.tweets.map(t => t.content).join(' ');

    // Here you would typically send this data to your AI model for analysis
    // For now, we'll just return a placeholder
    return (
      <div className="mt-6">
        <h3 className="text-lg font-semibold mb-2">Action Report</h3>
        <p>Based on the user's prompts and recent tweets, here are some suggested actions:</p>
        <ul className="list-disc pl-5 mt-2">
          <li>Engage more with tweets about [common topic from prompts]</li>
          <li>Consider creating content around [frequent theme in tweets]</li>
          <li>Reach out to [mentioned account] for potential collaboration</li>
        </ul>
      </div>
    );
  };

  const renderProfileDetails = () => (
    <div className="space-y-6 text-foreground">
      {isLoading ? (
        <div className="flex justify-center items-center h-40">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-900 dark:border-gray-100"></div>
        </div>
      ) : profileAnalysis ? (
        <>
          <ReactMarkdown 
            className="text-sm prose prose-sm dark:prose-invert max-w-none"
            components={{
              h1: ({node, ...props}) => <h1 className="text-xl font-bold mb-2" {...props} />,
              h2: ({node, ...props}) => <h2 className="text-lg font-semibold mb-2" {...props} />,
              p: ({node, ...props}) => <p className="mb-2" {...props} />,
              ul: ({node, ...props}) => <ul className="list-disc pl-5 mb-2" {...props} />,
              ol: ({node, ...props}) => <ol className="list-decimal pl-5 mb-2" {...props} />,
              li: ({node, ...props}) => <li className="mb-1" {...props} />,
              strong: ({node, ...props}) => <strong className="font-bold" {...props} />,
              em: ({node, ...props}) => <em className="italic" {...props} />,
            }}
          >
            {profileAnalysis}
          </ReactMarkdown>
          {renderActionReport()}
          <h3 className="text-lg font-semibold mt-6 mb-2">Prompt History</h3>
          {renderPromptTable()}
        </>
      ) : (
        <p>No analysis available. Try refreshing the page or check back later.</p>
      )}
    </div>
  );

  if (!profile) return null;

  return (
    <>
      <Card 
        className={`w-full sm:w-64 h-auto sm:h-40 cursor-pointer transition-transform duration-200 hover:scale-105 ${isExample ? 'opacity-80' : ''}`}
        onClick={handleCardClick}
      >
        <CardContent className="p-4 flex flex-col h-full relative">
          <div className="absolute top-2 right-2 flex items-center bg-green-100 dark:bg-green-900 rounded-full px-2 py-1">
            <Sparkles className="w-4 h-4 text-green-500 mr-1" />
            <span className="text-xs font-bold text-green-700 dark:text-green-300">Lvl {level}</span>
          </div>
          <TwitterHandle
            handle={profile.handle}
            displayName={profile.displayName}
            profileImageUrl={profile.profileImageUrl}
            showRemove={false}
            isOnCard={true}
          />
          <Progress value={progress} className="w-full h-2 mb-2 mt-2" />
          <div className="grid grid-cols-2 gap-2 text-xs">
            <div>Tweets: {formatNumber(tweets?.length || 0)}</div>
            <div>Insights: {formatNumber(insights?.length || 0)}</div>
          </div>
          <div className="text-xs text-gray-500 mt-auto">
            Last Active: {tweets?.length > 0 ? formatDistanceToNow(new Date(tweets[0].createdAt), { addSuffix: true }) : 'N/A'}
          </div>
        </CardContent>
      </Card>

      {!isExample && (
        <DialogOrSheet open={isDialogOpen} onOpenChange={handleDialogClose}>
          <DialogOrSheetContent className={`${isMobile ? '' : 'sm:max-w-[600px]'} max-h-[80vh] overflow-y-auto`}>
            <DialogOrSheetHeader>
              <div className="flex justify-between items-start">
                <div>
                  <DialogOrSheetTitle>
                    <TwitterHandle
                      handle={profile.handle}
                      displayName={profile.displayName}
                      profileImageUrl={profile.profileImageUrl}
                      showRemove={false}
                    />
                  </DialogOrSheetTitle>
                </div>
                <div className="flex items-center space-x-2">
                  <Button
                    onClick={() => setIsDeleting(true)}
                    variant="ghost"
                    size="icon"
                  >
                    <Trash2 className="h-4 w-4 text-gray-500" />
                  </Button>
                  <Button
                    onClick={handleDialogClose}
                    variant="ghost"
                    size="icon"
                  >
                    <X className="h-4 w-4" />
                  </Button>
                </div>
              </div>
            </DialogOrSheetHeader>
            {isDeleting && (
              <div className="bg-red-100 dark:bg-red-900 p-4 rounded-md mb-4">
                <p className="text-sm text-red-800 dark:text-red-200 mb-2">Are you sure you want to delete this Persona?</p>
                <div className="flex justify-end space-x-2">
                  <Button onClick={() => setIsDeleting(false)} variant="outline" size="sm">Cancel</Button>
                  <Button onClick={handleDeleteProfile} variant="destructive" size="sm">
                    Delete
                  </Button>
                </div>
              </div>
            )}
            {renderProfileDetails()}
          </DialogOrSheetContent>
        </DialogOrSheet>
      )}
    </>
  );
});

export default ProfileCard;