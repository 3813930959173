import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { Input } from "./ui/input";
import { Button } from "./ui/button";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "./ui/table";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { Trash2, Plus, Minus } from 'lucide-react';

const AdminDashboard = () => {
  const [analytics, setAnalytics] = useState(null);
  const [users, setUsers] = useState([]);  // Initialize as an empty array
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    fetchAnalytics();
    fetchUsers();
  }, []);

  const fetchAnalytics = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/analytics`);
      const data = await response.json();
      setAnalytics(data);
    } catch (error) {
      console.error('Error fetching analytics:', error);
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/users`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log('Fetched users:', data); // Log the fetched data
      setUsers(Array.isArray(data) ? data : []);
    } catch (error) {
      console.error('Error fetching users:', error);
      // Display an error message to the user
      setUsers([{ id: 'error', email: 'Error fetching users. Please try again.' }]);
    }
  };

  const adjustCredits = async (userId, amount) => {
    try {
      await fetch(`${process.env.REACT_APP_API_URL}/api/admin/adjust-credits`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userId, amount }),
      });
      fetchUsers(); // Refresh user list
    } catch (error) {
      console.error('Error adjusting credits:', error);
    }
  };

  const deleteUser = async (userId) => {
    if (window.confirm('Are you sure you want to delete this user? This action cannot be undone.')) {
      try {
        await fetch(`${process.env.REACT_APP_API_URL}/api/admin/delete-user`, {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ userId }),
        });
        fetchUsers(); // Refresh user list
      } catch (error) {
        console.error('Error deleting user:', error);
      }
    }
  };

  const filteredUsers = Array.isArray(users) ? users.filter(user => 
    user.id.toLowerCase().includes(searchTerm.toLowerCase()) ||
    (user.email && user.email.toLowerCase().includes(searchTerm.toLowerCase()))
  ) : [];

  return (
    <div className="space-y-4">
      <h1 className="text-2xl font-bold mb-4">Admin Dashboard</h1>
      
      {analytics && (
        <div className="grid grid-cols-2 sm:grid-cols-4 gap-2 mb-4">
          <StatCard title="Total Users" value={analytics.totalUsers} />
          <StatCard title="Daily Active Users" value={analytics.dailyActiveUsers} />
          <StatCard title="Total Trackers" value={analytics.totalTrackers} />
          <StatCard title="Total Credits Purchased" value={analytics.totalCreditsPurchased} />
        </div>
      )}

      <ChartCard title="User Growth" data={analytics?.userGrowth} dataKey="users" />
      <ChartCard title="Tracker Growth" data={analytics?.trackerGrowth} dataKey="trackers" />
      <ChartCard title="Truffles Found Over Time" data={analytics?.trufflesFound} dataKey="truffles" />

      <Card>
        <CardHeader>
          <CardTitle>User Management</CardTitle>
        </CardHeader>
        <CardContent>
          <Input
            placeholder="Search users..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="mb-4"
          />
          <div className="overflow-x-auto">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className="w-1/4">ID/Email</TableHead>
                  <TableHead className="w-1/6">Credits</TableHead>
                  <TableHead className="w-1/6">Trackers</TableHead>
                  <TableHead className="w-1/6">Truffles</TableHead>
                  <TableHead className="w-1/4">Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {filteredUsers.map(user => (
                  <TableRow key={user.id}>
                    <TableCell className="font-medium">
                      {user.email || user.id}
                      {user.walletAddresses && (
                        <div className="text-xs text-gray-500">
                          Wallet: {user.walletAddresses[0]}
                        </div>
                      )}
                    </TableCell>
                    <TableCell>{user.credits}</TableCell>
                    <TableCell>{user.totalTrackers}</TableCell>
                    <TableCell>{user.totalTruffles}</TableCell>
                    <TableCell>
                      <div className="flex space-x-1">
                        <Button onClick={() => adjustCredits(user.id, 100)} size="sm">
                          <Plus className="h-4 w-4" />
                        </Button>
                        <Button onClick={() => adjustCredits(user.id, -100)} variant="destructive" size="sm">
                          <Minus className="h-4 w-4" />
                        </Button>
                        <Button onClick={() => deleteUser(user.id)} variant="ghost" size="sm">
                          <Trash2 className="h-4 w-4" />
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

const StatCard = ({ title, value }) => (
  <Card>
    <CardHeader className="p-2">
      <CardTitle className="text-sm">{title}</CardTitle>
    </CardHeader>
    <CardContent className="p-2">
      <p className="text-xl font-bold">{value}</p>
    </CardContent>
  </Card>
);

const ChartCard = ({ title, data, dataKey }) => (
  <Card className="mb-4">
    <CardHeader>
      <CardTitle>{title}</CardTitle>
    </CardHeader>
    <CardContent>
      <div className="h-64">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={data || []}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Line type="monotone" dataKey={dataKey} stroke="#8884d8" />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </CardContent>
  </Card>
);

export default AdminDashboard;