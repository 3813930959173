import React, { createContext, useState, useContext, useCallback } from 'react';

const ProfileContext = createContext();

export const ProfileProvider = ({ children }) => {
  const [profileData, setProfileData] = useState({});

  const fetchProfileData = useCallback(async (userId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/profile-cards?userId=${userId}`);
      const data = await response.json();
      setProfileData(prevData => {
        // Only update if the data has changed
        if (JSON.stringify(prevData) !== JSON.stringify(data)) {
          return data;
        }
        return prevData;
      });
    } catch (error) {
      console.error('Error fetching profile data:', error);
    }
  }, []);

  const removeProfile = useCallback((profileId) => {
    setProfileData(prevData => {
      const newData = { ...prevData };
      delete newData[profileId];
      return newData;
    });
  }, []);

  const value = React.useMemo(() => ({ 
    profileData, 
    fetchProfileData, 
    removeProfile 
  }), [profileData, fetchProfileData, removeProfile]);

  return (
    <ProfileContext.Provider value={value}>
      {children}
    </ProfileContext.Provider>
  );
};

export const useProfile = () => useContext(ProfileContext);
