import React from 'react';

const SkeletonLoader = ({ isDarkMode }) => {
  return (
    <div className={`space-y-6 ${isDarkMode ? 'dark' : ''}`}>
      {/* Credits widget skeleton */}
      <div className="border border-gray-200 dark:border-gray-700 p-4 rounded-xl animate-pulse -mt-16 bg-background/80 backdrop-blur-md custom-shadow">
        <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
          <div className="flex flex-row justify-between sm:justify-start sm:space-x-8 mb-4 sm:mb-0">
            <div>
              <div className="h-5 bg-gray-200 dark:bg-gray-700 rounded w-24 mb-1"></div>
              <div className="h-8 bg-gray-200 dark:bg-gray-700 rounded w-32 text-2xl"></div>
            </div>
            <div className="text-right sm:text-left">
              <div className="h-5 bg-gray-200 dark:bg-gray-700 rounded w-28 mb-1"></div>
              <div className="h-8 bg-gray-200 dark:bg-gray-700 rounded w-20 text-2xl"></div>
            </div>
          </div>
          <div className="flex justify-center sm:justify-end mt-4 sm:mt-0">
            <div className="h-10 bg-gray-200 dark:bg-gray-700 rounded-full w-48"></div>
          </div>
        </div>
      </div>
      
      {/* Profile cards skeleton */}
      <div className="w-full overflow-x-auto mb-6">
        <div className="flex space-x-4 p-4 min-w-max">
          {[1, 2, 3].map((i) => (
            <div key={i} className="w-64 h-40 bg-gray-200 dark:bg-gray-700 rounded-xl animate-pulse">
              <div className="h-10 bg-gray-300 dark:bg-gray-600 rounded-t-xl"></div>
              <div className="p-4">
                <div className="h-4 bg-gray-300 dark:bg-gray-600 rounded w-3/4 mb-2"></div>
                <div className="h-2 bg-gray-300 dark:bg-gray-600 rounded w-full mb-2"></div>
                <div className="h-2 bg-gray-300 dark:bg-gray-600 rounded w-1/2"></div>
              </div>
            </div>
          ))}
        </div>
      </div>
      
      {/* Tracker cards skeleton */}
      <div className="space-y-4">
        {[1, 2, 3].map((i) => (
          <div key={i} className="border border-gray-200 dark:border-gray-700 rounded-xl animate-pulse custom-shadow">
            <div className="p-2 sm:p-3">
              <div className="flex justify-between items-start mb-3">
                <div className="flex items-center space-x-2">
                  <div className="rounded-full bg-gray-200 dark:bg-gray-700 h-8 w-8"></div>
                  <div className="flex-1 space-y-1">
                    <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded-full w-24 sm:w-32"></div>
                    <div className="h-3 bg-gray-200 dark:bg-gray-700 rounded-full w-20 sm:w-24"></div>
                  </div>
                </div>
                <div className="h-5 w-10 bg-gray-200 dark:bg-gray-700 rounded-full"></div>
              </div>
              <div className="h-6 bg-gray-200 dark:bg-gray-700 rounded-full w-3/4 mb-3"></div>
            </div>
            <div className="p-2 sm:p-3 pt-0">
              <div className="flex flex-wrap items-center justify-between gap-2">
                <div className="flex space-x-2">
                  <div className="h-8 bg-gray-200 dark:bg-gray-700 rounded-full w-24"></div>
                  <div className="h-8 bg-gray-200 dark:bg-gray-700 rounded-full w-16"></div>
                  <div className="h-8 bg-gray-200 dark:bg-gray-700 rounded-full w-20"></div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SkeletonLoader;
