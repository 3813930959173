import React from 'react';
import { Card, CardContent } from "./ui/card";
import TwitterHandle from './TwitterHandle';
import { formatDistanceToNow } from 'date-fns';
import ReactMarkdown from 'react-markdown';
import { Button } from "./ui/button";
import { Switch } from "./ui/switch";

const ExampleReminders = ({ onSignIn, isDarkMode }) => {
  const exampleTrackers = [
    {
      id: 'example1',
      profiles: [
        {
          handle: '@elonmusk',
          displayName: 'Elon Musk',
          profileImageUrl: 'https://pbs.twimg.com/profile_images/1590968738358079488/IY9Gx6Ok_400x400.jpg',
        }
      ],
      description: "Letting you know when Elon Musk discusses artificial intelligence or AI-related topics.",
      frequency: 'Daily',
      lastChecked: '2023-10-15T10:30:00Z',
      isActive: true,
      includeReplies: false,
      triggerEvents: [
        {
          id: 'truffle1',
          triggeredAt: '2023-10-15T11:45:00Z',
          analysis: "Elon Musk shared groundbreaking AI research from Tesla, hinting at a major leap in autonomous driving capabilities. [Link↗](https://x.com/elonmusk/status/1234567890)",
        },
        {
          id: 'truffle2',
          triggeredAt: '2023-10-14T09:30:00Z',
          analysis: "Elon Musk announced a collaboration between Tesla and DeepMind for developing safer AI systems, potentially revolutionizing the field. [Link↗](https://x.com/elonmusk/status/2345678901)",
        }
      ],
      insights: [
        {
          content: "I found that Elon Musk's tweets about AI have become more frequent and technical, focusing on safety concerns and potential breakthroughs.\n\n• Increased engagement with AI researchers, sharing technical papers on neural networks.\n• Hints at AI advancements at Tesla, possibly related to Full Self-Driving capabilities.\n• Advocates for AI regulation while pushing for rapid development, showing a nuanced stance.\n• Draws parallels between AI progress and societal impacts, echoing Neuralink's mission.\n• Potential preparation for a major AI-related announcement, given the surge in technical content.",
          createdAt: '2023-10-14T09:00:00Z',
          tweetCount: 25,
        }
      ],
    },
    {
      id: 'example2',
      profiles: [
        {
          handle: '@elonmusk',
          displayName: 'Elon Musk',
          profileImageUrl: 'https://pbs.twimg.com/profile_images/1590968738358079488/IY9Gx6Ok_400x400.jpg',
        },
        {
          handle: '@sama',
          displayName: 'Sam Altman',
          profileImageUrl: 'https://pbs.twimg.com/profile_images/804990434455887872/BG0Xh7Oa_400x400.jpg',
        },
        {
          handle: '@vitalikbuterin',
          displayName: 'Vitalik Buterin',
          profileImageUrl: 'https://pbs.twimg.com/profile_images/977496875887558661/L86xyLF4_400x400.jpg',
        }
      ],
      description: "Letting you know on their views on AI, blockchain, and the future of technology.",
      frequency: 'Weekly',
      lastChecked: '2023-10-14T15:45:00Z',
      isActive: true,
      includeReplies: true,
      triggerEvents: [],
      insights: [
        {
          content: "Recent analysis of Elon Musk, Sam Altman, and Vitalik Buterin's tweets reveals:\n\n• AI Safety: Musk emphasizes regulation, Altman balances benefits and responsible development, Buterin explores AI-blockchain intersections.\n• Innovation: All three discuss breakthrough technologies, with Musk focusing on neural interfaces, Altman on language models, and Buterin on scalable blockchain solutions.\n• Societal Impact: Shared concern about AI's effect on jobs, but differing views on mitigation strategies.\n• Collaboration: Hints at potential joint projects or shared research interests in AI ethics and governance.\n• Future Predictions: Convergence on the transformative potential of AI, with nuanced perspectives on timeline and optimal development paths.",
          createdAt: '2023-10-13T14:30:00Z',
          tweetCount: 25,
        }
      ],
    },
  ];

  const renderTruffle = (event, isDarkMode) => (
    <div key={event.id} className={`mt-2 rounded-lg ${isDarkMode ? 'bg-gray-700 text-gray-200' : 'bg-white text-gray-800'}`}>
      <div className={`p-2 flex flex-wrap items-center justify-between`}>
        <p className="text-sm font-bold flex items-center mr-2">
          🍄‍🟫 <strong>Found truffle!</strong>
        </p>
        <p className="text-sm">{formatDistanceToNow(new Date(event.triggeredAt), { addSuffix: true })}</p>
      </div>
      <div className="p-2 pt-0">
        <ReactMarkdown 
          className={`text-sm prose prose-sm max-w-none font-sans ${isDarkMode ? 'text-gray-200' : 'text-gray-800'}`}
          components={{
            p: ({node, ...props}) => <p className="font-sans mb-2" {...props} />,
            a: ({node, ...props}) => <a target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline" {...props} />,
          }}
        >
          {event.analysis}
        </ReactMarkdown>
      </div>
    </div>
  );

  const renderInsight = (insight, isDarkMode) => (
    <div key={insight.createdAt} className={`mt-2 rounded-lg ${
      isDarkMode 
        ? 'bg-indigo-900 text-indigo-100' 
        : 'bg-indigo-50 text-indigo-800'
    }`}>
      <div className={`p-2 flex flex-wrap items-center justify-between`}>
        <p className="text-sm font-bold flex items-center mr-2">
          💡 <strong>Insight!</strong>
        </p>
        <p className="text-sm">
          Based on last {insight.tweetCount} posts
        </p>
      </div>
      <div className="p-2 pt-0">
        <ReactMarkdown 
          className={`text-sm prose prose-sm max-w-none font-sans ${
            isDarkMode ? 'text-indigo-200' : 'text-indigo-800'
          }`}
          components={{
            p: ({node, ...props}) => <p className="font-sans mb-2" {...props} />,
            li: ({node, ...props}) => <li className="font-sans list-disc ml-4" {...props} />,
            ul: ({node, ...props}) => <ul className="mb-2" {...props} />,
          }}
        >
          {insight.content}
        </ReactMarkdown>
      </div>
    </div>
  );

  return (
    <div className="space-y-4">
      {exampleTrackers.map((tracker) => (
        <div key={tracker.id} className="relative group">
          <Card className={`border-border rounded-xl overflow-hidden bg-gray-50 dark:bg-gray-800 ${!tracker.isActive ? 'opacity-50' : ''}`}>
            <div className="bg-gray-100 dark:bg-gray-900 p-0">
              <div className="flex flex-wrap p-2">
                {tracker.profiles.map((profile, index) => (
                  <TwitterHandle
                    key={index}
                    handle={profile.handle}
                    displayName={profile.displayName}
                    profileImageUrl={profile.profileImageUrl}
                    showRemove={false}
                    isOnCard={true}
                  />
                ))}
              </div>
              <div className="w-full border-b border-gray-200 dark:border-gray-700"></div>
            </div>
            <CardContent className="p-2 sm:p-3">
              <div className="flex justify-between items-center mb-1">
                <p className="text-xl font-semibold text-card-foreground">{tracker.description}</p>
                <Switch checked={tracker.isActive} className="ml-2 flex-shrink-0" />
              </div>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                <span className="font-normal">10 credits</span> · Check {tracker.frequency.toLowerCase()} · 
                {tracker.includeReplies ? " Posts & replies" : " Posts only"} ·
                Last checked {formatDistanceToNow(new Date(tracker.lastChecked), { addSuffix: true })}
              </p>
              {tracker.triggerEvents.map(event => renderTruffle(event, isDarkMode))}
              {tracker.insights.map(insight => renderInsight(insight, isDarkMode))}
            </CardContent>
          </Card>
          <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-xl">
            <div className="text-center">
              <p className="text-white text-lg mb-4">Get started with 1000 free credits</p>
              <Button onClick={onSignIn} variant="primary">Sign In</Button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ExampleReminders;
