import React from 'react';
import ProfileCard from './ProfileCard';

const ExampleProfiles = () => {
  const exampleProfiles = [
    {
      id: 'example1',
      handle: '@elonmusk',
      displayName: 'Elon Musk',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1590968738358079488/IY9Gx6Ok_400x400.jpg',
      tweets: Array(50).fill({ createdAt: '2023-10-15T10:30:00Z' }),
      insights: Array(10).fill({}),
      level: 3,
    },
    {
      id: 'example2',
      handle: '@naval',
      displayName: 'Naval',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1256841238298292232/ycqwaMI2_400x400.jpg',
      tweets: Array(30).fill({ createdAt: '2023-10-14T15:45:00Z' }),
      insights: Array(5).fill({}),
      level: 2,
    },
    {
      id: 'example3',
      handle: '@ycombinator',
      displayName: 'Y Combinator',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1623777064821358592/9CApQWXe_400x400.png',
      tweets: Array(40).fill({ createdAt: '2023-10-13T20:00:00Z' }),
      insights: Array(8).fill({}),
      level: 3,
    },
    {
      id: 'example4',
      handle: '@sama',
      displayName: 'Sam Altman',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/804990434455887872/BG0Xh7Oa_400x400.jpg',
      tweets: Array(20).fill({ createdAt: '2023-10-12T12:15:00Z' }),
      insights: Array(3).fill({}),
      level: 1,
    },
    {
      id: 'example5',
      handle: '@pmarca',
      displayName: 'Marc Andreessen',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1820716712234303489/9GpKDZjq_400x400.jpg',
      tweets: Array(35).fill({ createdAt: '2023-10-11T18:30:00Z' }),
      insights: Array(7).fill({}),
      level: 2,
    },
  ];

  return (
    <div className="w-full overflow-x-auto mb-6">
      <div className="flex space-x-4 p-4 min-w-max">
        {exampleProfiles.map((profile) => (
          <ProfileCard
            key={profile.id}
            profileId={profile.id}
            profile={profile}
            tweets={profile.tweets}
            insights={profile.insights}
            isExample={true}
          />
        ))}
      </div>
    </div>
  );
};

export default ExampleProfiles;
