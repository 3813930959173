import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { Bell, Trash2, Edit, Moon, Sun, AlertTriangle, CreditCard as CreditCardIcon, Loader2, Loader, LogOut, Plus, RefreshCw, X, BarChart, Lightbulb, ChevronDown, ChevronUp } from 'lucide-react';
import { Input } from "./ui/input";
import { Button } from "./ui/button";
import { Label } from "./ui/label";
import { Switch } from "./ui/switch";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { usePrivy } from '@privy-io/react-auth';
import ReactMarkdown from 'react-markdown';
import { useToast } from "./ui/use-toast";
import { Toaster } from "./ui/toaster";
import { useTheme } from '../contexts/ThemeContext';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter,
} from "./ui/dialog";
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetFooter, SheetTrigger, SheetClose } from "./ui/sheet";
import { urlBase64ToUint8Array } from '../utils/pushNotifications';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { debounce } from 'lodash';
import SkeletonLoader from './SkeletonLoader';
import { formatDistanceToNow } from 'date-fns';
import AdminDashboard from './AdminDashboard';
import { ToggleGroup, ToggleGroupItem } from "./ui/toggle-group";
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import StripeCheckoutForm from './StripeCheckoutForm';
import coinbaseLogo from '../assets/coinbase-logo.svg';
import stripeLogo from '../assets/stripe-logo.svg';
import ProfileCard from './ProfileCard';
import { ProfileProvider, useProfile } from '../contexts/ProfileContext';
import ExampleProfiles from './ExampleProfiles';
import ExampleReminders from './ExampleReminders';
import Reminders from './Reminders';

const formatNumber = (number) => {
  return new Intl.NumberFormat().format(number);
};

const useCache = () => {
  const cache = useMemo(() => new Map(), []);

  const getCachedData = useCallback(
    (key) => {
      const cachedItem = cache.get(key);
      if (cachedItem && Date.now() - cachedItem.timestamp < 60000) {
        // 1 minute cache
        return cachedItem.data;
      }
      return null;
    },
    [cache]
  );

  const setCachedData = useCallback(
    (key, data) => {
      cache.set(key, { data, timestamp: Date.now() });
    },
    [cache]
  );

  return { getCachedData, setCachedData };
};

const formatRelativeTime = (date) => {
  if (!date) return 'never';
  return formatDistanceToNow(new Date(date), { addSuffix: true });
};

const TwitterHandle = ({
  handle,
  displayName,
  profileImageUrl,
  onRemove,
  showRemove = true,
  isOnCard = false,
  onAdd,
}) => {
  const cleanHandle = handle ? handle.replace(/^@/, '') : '';
  return (
    <div
      className={`flex items-center space-x-2 mr-1 ${
        isOnCard ? '' : 'bg-gray-100 dark:bg-white/10'
      } rounded-full p-1`}
    >
      <a
        href={`https://x.com/${cleanHandle}`}
        target="_blank"
        rel="noopener noreferrer"
        className="flex items-center space-x-2 hover:opacity-80 active:opacity-60 active:scale-95 transition-all duration-150 w-full"
      >
        {profileImageUrl && (
          <img
            src={profileImageUrl}
            alt={`${displayName || handle || 'Profile'}'s profile`}
            className="w-6 h-6 rounded-full flex-shrink-0 object-cover"
          />
        )}
        <div className="flex-grow min-w-0">
          <div className="flex flex-wrap items-center">
            <span className="text-sm mr-1 break-words">
              {displayName || handle || 'Unknown'}
            </span>
            {cleanHandle && (
              <span className="text-xs text-gray-500 dark:text-gray-400 break-all">
                @{cleanHandle}
              </span>
            )}
          </div>
        </div>
      </a>
      {showRemove && onRemove && (
        <button
          onClick={onRemove}
          className="ml-2 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
        >
          <X className="h-4 w-4" />
        </button>
      )}
      {isOnCard && onAdd && (
        <button
          onClick={onAdd}
          className="ml-2 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
          title="Add to tracking list"
        >
          <Plus className="h-4 w-4" />
        </button>
      )}
    </div>
  );
};

const ReminderItem = React.memo(
  ({
    reminder,
    onCheck,
    onEdit,
    onDelete,
    onToggle,
    isDarkMode,
    isChecking,
    onGenerateInsight,
    isGeneratingInsight,
    onAddProfile,
  }) => {
    const [expandedInsights, setExpandedInsights] = useState({});
    const [newInsights, setNewInsights] = useState({});

    // Calculate creditCost
    const creditCost = reminder.profiles ? reminder.profiles.length * 10 : 10;

    useEffect(() => {
      if (reminder.insights) {
        const newExpandedState = {};
        const newInsightsState = {};
        reminder.insights.forEach((insight, index) => {
          if (insight.isNew) {
            newExpandedState[index] = true;
            newInsightsState[index] = true;
            setTimeout(() => {
              setNewInsights((prev) => ({ ...prev, [index]: false }));
            }, 3000);
          }
        });
        setExpandedInsights(newExpandedState);
        setNewInsights(newInsightsState);
      }
    }, [reminder.insights]);

    const toggleInsight = (index) => {
      setExpandedInsights((prev) => ({
        ...prev,
        [index]: !prev[index],
      }));
    };

    const renderInsight = (insight, index) => {
      if (!insight) return null;

      return (
        <div
          key={index}
          className={`mt-2 rounded-lg transition-all duration-300 ease-in-out ${
            isDarkMode ? 'bg-indigo-900 text-indigo-100' : 'bg-indigo-50 text-indigo-800'
          } ${newInsights[index] ? 'new-insight-effect' : ''}`}
        >
          <div
            className={`p-2 flex flex-wrap items-center justify-between cursor-pointer`}
            onClick={() => toggleInsight(index)}
          >
            <div className="flex items-center">
              <p className="text-sm font-bold flex items-center mr-2">
                💡 <strong>Insight!</strong>
              </p>
              {expandedInsights[index] ? (
                <ChevronUp className="h-4 w-4" />
              ) : (
                <ChevronDown className="h-4 w-4" />
              )}
            </div>
            <p className="text-sm">Based on last {insight.tweetCount} posts</p>
          </div>
          {expandedInsights[index] && (
            <div className="p-2 pt-0">
              <ReactMarkdown
                className={`text-sm prose prose-sm max-w-none font-sans ${
                  isDarkMode ? 'text-indigo-200' : 'text-indigo-800'
                }`}
                components={{
                  p: ({ node, ...props }) => <p className="font-sans mb-2" {...props} />,
                  li: ({ node, ...props }) => <li className="font-sans list-disc ml-4" {...props} />,
                  a: ({ node, ...props }) => (
                    <a target="_blank" rel="noopener noreferrer" {...props} />
                  ),
                  strong: ({ node, ...props }) => <strong className="font-bold" {...props} />,
                }}
              >
                {insight.content}
              </ReactMarkdown>
            </div>
          )}
        </div>
      );
    };

    const renderTruffle = (event, isDarkMode) => {
      return (
        <div
          key={event.id}
          className={`mt-2 rounded-lg ${
            isDarkMode ? 'bg-gray-700 text-gray-200' : 'bg-white text-gray-800'
          }`}
        >
          <div className={`p-2 flex flex-wrap items-center justify-between`}>
            <div className="flex items-center">
              <p className="text-sm font-bold flex items-center mr-2">
                🍄‍🟫 <strong>Found truffle!</strong>
              </p>
              {event.relevantProfiles && event.relevantProfiles.length > 0 && (
                <div className="flex -space-x-2 overflow-hidden">
                  {event.relevantProfiles.map((profile, i) => (
                    <img
                      key={i}
                      className="inline-block h-6 w-6 rounded-full ring-2 ring-white dark:ring-gray-800"
                      src={profile.profileImageUrl}
                      alt={`${profile.displayName || profile.handle}'s profile`}
                      title={profile.displayName || profile.handle}
                    />
                  ))}
                </div>
              )}
            </div>
            <p className="text-sm">{formatRelativeTime(event.triggeredAt)}</p>
          </div>
          <div className="p-2 pt-0">
            <ReactMarkdown
              className={`text-sm prose prose-sm max-w-none font-sans ${
                isDarkMode ? 'text-gray-200' : 'text-gray-800'
              }`}
              components={{
                p: ({ node, ...props }) => <p className="font-sans mb-2" {...props} />,
                li: ({ node, ...props }) => <li className="font-sans" {...props} />,
                a: ({ node, ...props }) => (
                  <a target="_blank" rel="noopener noreferrer" {...props} />
                ),
              }}
            >
              {event.analysis}
            </ReactMarkdown>
          </div>
        </div>
      );
    };

    const combinedEvents = useMemo(() => {
      const events = [...(reminder.triggerEvents || [])];
      if (reminder.insights && reminder.insights.length > 0) {
        events.push(
          ...reminder.insights.map((insight) => ({
            ...insight,
            type: 'insight',
            triggeredAt: insight.createdAt,
          }))
        );
      }
      return events.sort((a, b) => new Date(b.triggeredAt) - new Date(a.triggeredAt));
    }, [reminder.triggerEvents, reminder.insights]);

    return (
      <Card
        className={`${
          !reminder.isActive ? 'opacity-50' : ''
        } border-border rounded-xl overflow-hidden bg-gray-50 dark:bg-gray-800`}
      >
        <div className="bg-gray-100 dark:bg-gray-900 p-0">
          <div className="flex flex-wrap p-2">
            {reminder.profiles && reminder.profiles.length > 0 ? (
              reminder.profiles.map((profile, index) => (
                <TwitterHandle
                  key={index}
                  handle={profile.handle}
                  displayName={profile.displayName}
                  profileImageUrl={profile.profileImageUrl}
                  showRemove={false}
                  isOnCard={true}
                  onAdd={() => onAddProfile(profile)}
                />
              ))
            ) : (
              <TwitterHandle
                handle={reminder.profileToTrack}
                displayName={reminder.displayName}
                profileImageUrl={reminder.profileImageUrl}
                showRemove={false}
                isOnCard={true}
                onAdd={() =>
                  onAddProfile({
                    handle: reminder.profileToTrack,
                    displayName: reminder.displayName,
                    profileImageUrl: reminder.profileImageUrl,
                  })
                }
              />
            )}
          </div>
          <div className="w-full border-b border-gray-200 dark:border-gray-700"></div>
        </div>
        <CardHeader className="p-2 sm:p-3">
          <div className="flex justify-between items-center mb-1">
            <p className="text-xl font-semibold text-card-foreground">
              {reminder.reformulatedPrompt || reminder.description}
            </p>
            <Switch
              checked={reminder.isActive}
              onCheckedChange={() => onToggle(reminder.id, reminder.isActive)}
              className="ml-2 flex-shrink-0"
            />
          </div>
          <p className="text-sm text-gray-500 dark:text-gray-400">
            <span className="font-normal">{creditCost} credits</span> · Check{' '}
            {reminder.frequency.toLowerCase()} ·
            {reminder.includeReplies ? ' Posts & replies' : ' Posts only'} · Last checked{' '}
            {formatRelativeTime(reminder.lastChecked)}
          </p>
        </CardHeader>
        <CardContent className="pt-0 p-2 sm:p-3">
          <div className="flex flex-wrap items-center gap-2">
            <Button
              onClick={() => onCheck(reminder.id)}
              size="sm"
              variant="secondaryCompact"
              disabled={isChecking}
              className="bg-gray-100 dark:bg-white/5 hover:bg-gray-200 dark:hover:bg-white/10"
            >
              {isChecking ? (
                <>
                  <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                  Checking...
                </>
              ) : (
                <>
                  <RefreshCw className="h-4 w-4 mr-2" />
                  Check now
                </>
              )}
            </Button>
            <Button
              onClick={() => onGenerateInsight(reminder.id)}
              size="sm"
              variant="secondaryCompact"
              disabled={isGeneratingInsight}
              className="bg-gray-100 dark:bg-white/5 hover:bg-gray-200 dark:hover:bg-white/10"
            >
              {isGeneratingInsight ? (
                <>
                  <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                  Generating...
                </>
              ) : (
                <>
                  <Lightbulb className="h-4 w-4 mr-2" />
                  Get Insight
                </>
              )}
            </Button>
            <Button
              onClick={() => onEdit(reminder.id)}
              size="sm"
              variant="secondaryCompact"
              className="bg-gray-100 dark:bg-white/5 hover:bg-gray-200 dark:hover:bg-white/10"
            >
              <Edit className="h-4 w-4 mr-2" /> Edit
            </Button>
            <Button
              onClick={() => onDelete(reminder.id)}
              size="sm"
              variant="secondaryCompact"
              className="bg-gray-100 dark:bg-white/5 hover:bg-gray-200 dark:hover:bg-white/10"
            >
              <Trash2 className="h-4 w-4 mr-2" /> Delete
            </Button>
          </div>
          {combinedEvents.map((event, index) => (
            <React.Fragment key={event.id || `insight-${index}`}>
              {event.type === 'insight'
                ? renderInsight(event, index)
                : renderTruffle(event, isDarkMode)}
            </React.Fragment>
          ))}
        </CardContent>
      </Card>
    );
  }
);

const CreditsWidget = ({
  credits,
  monthlySpend,
  onBuyCredits,
  isPendingTransaction,
  isDarkMode,
  onRefresh,
}) => (
  <Card className="border-border bg-background">
    <CardContent className="p-4">
      <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
        <div className="flex flex-row justify-between sm:justify-start sm:space-x-8 mb-4 sm:mb-0">
          <div>
            <div className="flex items-center mb-1">
              <CardTitle className="text-lg text-card-foreground mr-2">Your Credits</CardTitle>
              <Button
                onClick={onRefresh}
                variant="ghost"
                size="icon"
                className="h-6 w-6 p-0"
              >
                <RefreshCw className="h-4 w-4 text-gray-400" />
              </Button>
            </div>
            <div className="text-2xl font-bold">{formatNumber(credits)}</div>
          </div>
          <div className="text-right sm:text-left">
            <CardTitle className="text-lg text-muted-foreground mb-1">Monthly Spend</CardTitle>
            <div className="text-2xl font-bold text-muted-foreground">{formatNumber(monthlySpend)}</div>
          </div>
        </div>
        <div className="flex justify-center sm:justify-end">
          <Button
            onClick={onBuyCredits}
            className={`w-full sm:w-auto 
                  rounded-full font-medium
                  bg-darkGreen-start/5 text-darkGreen-end
                  hover:bg-darkGreen-start/10 hover:text-darkGreen-end
                  dark:bg-darkGreen-start/10 dark:text-darkGreen-start
                  dark:hover:bg-darkGreen-start/20 dark:hover:text-darkGreen-start
                  transition-colors duration-200
                `}
          >
            <CreditCardIcon className="mr-2 h-4 w-4" /> Buy Credits
          </Button>
        </div>
      </div>
    </CardContent>
  </Card>
);

const fetchWithRetry = async (url, options = {}, maxRetries = 3) => {
  for (let i = 0; i < maxRetries; i++) {
    try {
      const response = await fetch(url, options);
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      return await response.json();
    } catch (error) {
      if (i === maxRetries - 1) throw error;
      await new Promise((resolve) => setTimeout(resolve, 1000 * Math.pow(2, i)));
    }
  }
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const TruffleApp = () => {
  const { login, logout, authenticated, user, ready } = usePrivy();
  const [frequency, setFrequency] = useState("Daily"); // Changed to "Daily" as default
  const [reminders, setReminders] = useState([]);
  const [newReminder, setNewReminder] = useState("");
  const [profileToTrack, setProfileToTrack] = useState("");
  const [email, setEmail] = useState("");  // Remove the default email
  const [credits, setCredits] = useState(0);
  const [editingReminder, setEditingReminder] = useState(null);
  const [isPushEnabled, setIsPushEnabled] = useLocalStorage('isPushEnabled', false);
  const [isAddingReminder, setIsAddingReminder] = useState(false);
  const [checkingReminders, setCheckingReminders] = useState({});
  const [userId, setUserId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showContent, setShowContent] = useState(false);
  const [profileImageUrl, setProfileImageUrl] = useState("");
  const { toast } = useToast();
  const { isDarkMode, toggleDarkMode } = useTheme();
  const [showPushDialog, setShowPushDialog] = useState(false);
  const [showBuyCreditsDialog, setShowBuyCreditsDialog] = useState(false);
  const [creditAmount, setCreditAmount] = useState(500);
  const [usdcAmount, setUsdcAmount] = useState(5);
  const [formProfileImageUrl, setFormProfileImageUrl] = useState('');
  const [isPendingTransaction, setIsPendingTransaction] = useLocalStorage('isPendingTransaction', false);
  const [pendingTransactionStartTime, setPendingTransactionStartTime] = useLocalStorage('pendingTransactionStartTime', null);
  const [profileDisplayName, setProfileDisplayName] = useState('');
  const [inputProfileToTrack, setInputProfileToTrack] = useState("");
  const [inputDescription, setInputDescription] = useState("");
  const [inputEmail, setInputEmail] = useState("");
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const { getCachedData, setCachedData } = useCache();
  const [authState, setAuthState] = useState('loading'); // 'loading', 'authenticated', or 'unauthenticated'
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isPollingCredits, setIsPollingCredits] = useState(false);
  const pollingTimeoutRef = useRef(null);
  const [isEditingInDrawer, setIsEditingInDrawer] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [offlineToastId, setOfflineToastId] = useState(null);
  const [profileInfo, setProfileInfo] = useState(null);
  const [selectedProfiles, setSelectedProfiles] = useState([]);
  const [newVersionAvailable, setNewVersionAvailable] = useState(false);
  const [waitingWorker, setWaitingWorker] = useState(null);
  const [dismissedVersion, setDismissedVersion] = useState(null);
  const [showAdminDashboard, setShowAdminDashboard] = useState(false);
  const [showSubtext, setShowSubtext] = useState(true);
  const [generatingInsights, setGeneratingInsights] = useState({});
  const [includeReplies, setIncludeReplies] = useState("only-posts");
  const [placeholderPrompt, setPlaceholderPrompt] = useState("");
  const [debouncedProfileToTrack, setDebouncedProfileToTrack] = useState("");
  const [showStripeDialog, setShowStripeDialog] = useState(false);
  const [clientSecret, setClientSecret] = useState('');
  const [profileCards, setProfileCards] = useState([]);
  const { fetchProfileData, profileData } = useProfile();

  const updateStatusBarStyle = useCallback((isDark) => {
    const metaStatusBar = document.getElementById('apple-status-bar-style');
    if (metaStatusBar) {
      metaStatusBar.setAttribute('content', isDark ? 'black' : 'default');
    }
    // Also update the theme-color meta tag for Android devices
    const metaThemeColor = document.getElementById('theme-color-meta');
    if (metaThemeColor) {
      metaThemeColor.setAttribute('content', isDark ? '#000000' : '#ffffff');
    }
  }, []);

  useEffect(() => {
    updateStatusBarStyle(isDarkMode);
  }, [isDarkMode, updateStatusBarStyle]);

  const examplePromptsRef = useRef([
    "when they tweet about new product features or updates",
    "if they mention our competitors or similar products",
    "when they share insights about industry trends",
    "if they post job openings or hint at team expansion",
    "when they discuss challenges or pain points in our industry",
    "if they announce partnerships or collaborations",
    "when they share customer success stories or testimonials",
    "if they mention specific technologies or tools we're interested in",
    "when they talk about upcoming events or conferences",
    "if they discuss regulatory changes affecting our industry"
  ]);

  const currentPlaceholderRef = useRef("");

  const getRandomPrompt = useCallback(() => {
    const prompts = examplePromptsRef.current;
    return prompts[Math.floor(Math.random() * prompts.length)];
  }, []);

  const updatePlaceholder = useCallback(() => {
    currentPlaceholderRef.current = getRandomPrompt();
  }, [getRandomPrompt]);

  // Set initial placeholder
  useEffect(() => {
    updatePlaceholder();
  }, [updatePlaceholder]);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
      if (authenticated) {
        // Attempt to reconnect Privy
        try {
          login();
        } catch (error) {
          console.error('Failed to reconnect to Privy:', error);
        }
      }
    };
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, [authenticated, login]);

  useEffect(() => {
    if (!isOnline) {
      const id = toast({
        title: "You are offline",
        description: "Some features may be unavailable until you reconnect.",
        duration: null, // Keep the toast visible until dismissed
      });
      setOfflineToastId(id);
    } else if (offlineToastId) {
      toast({
        id: offlineToastId,
        title: "You are back online",
        description: "All features are now available.",
        duration: 3000,
      });
      setOfflineToastId(null);
    }
  }, [isOnline, toast]);

  useEffect(() => {
    if (ready) {
      if (authenticated && user) {
        loginUser();
      } else {
        setAuthState('unauthenticated');
        setIsLoading(false);
        setShowContent(true);
      }
    }
  }, [ready, authenticated, user]);

  // Wrap Privy-related functions to catch potential errors
  const safeLogin = useCallback(async () => {
    try {
      await login();
    } catch (error) {
      console.error('Login error:', error);
      toast({
        title: "Login Error",
        description: "Failed to log in. Please try again.",
        variant: "destructive",
      });
    }
  }, [login]);

  const safeLogout = useCallback(async () => {
    try {
      await logout();
    } catch (error) {
      console.error('Logout error:', error);
      // Force logout on the client side even if there's an error
      setAuthState('unauthenticated');
      setReminders([]);
    }
  }, [logout]);

  const loginUser = async () => {
    setAuthState('authenticated');
    try {
      const walletAddresses = user.linkedAccounts
        .filter(account => account.type === 'wallet')
        .map(account => account.address);

      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          privyId: user.id,
          walletAddresses: walletAddresses
        }),
      });
      console.log('Response status:', response.status);
      console.log('Response headers:', response.headers);

      const loginResponse = await response.json();

      setUserId(loginResponse.userId);
      setCredits(loginResponse.credits);

      const [remindersResponse, creditsResponse] = await Promise.all([
        fetchWithRetry(`${process.env.REACT_APP_API_URL}/api/reminders?userId=${loginResponse.userId}`),
        fetchWithRetry(`${process.env.REACT_APP_API_URL}/api/credits?userId=${loginResponse.userId}`)
      ]);

      setReminders(remindersResponse);
      setCredits(creditsResponse.credits);

      setIsLoading(false);
      setShowContent(true);

      toast({
        title: "Logged In",
        description: "You have successfully logged in.",
        duration: 3000,
      });
    } catch (error) {
      console.error('Error logging in user:', error);
      toast({
        title: "Login Error",
        description: "Failed to log in. Please try again.",
        variant: "destructive",
        duration: 5000,
      });
      setIsLoading(false);
      setAuthState('unauthenticated');
    }
  };

  const fetchReminders = useCallback(async (userId) => {
    const cachedReminders = getCachedData(`reminders-${userId}`);
    if (cachedReminders) {
      setReminders(cachedReminders);
      return;
    }

    try {
      const data = await fetchWithRetry(`${process.env.REACT_APP_API_URL}/api/reminders?userId=${userId}`);
      setCachedData(`reminders-${userId}`, data);
      setReminders(data.map(reminder => ({
        ...reminder,
        tweetCount: reminder.tweets ? reminder.tweets.length : 0
      })));

      // Fetch additional details for each reminder
      data.forEach(reminder => {
        fetchReminderDetails(reminder.id);
      });
    } catch (error) {
      console.error('Error fetching reminders:', error);
      toast({
        title: "Error",
        description: "Failed to fetch reminders. Please try again.",
        variant: "destructive",
      });
    }
  }, [getCachedData, setCachedData]);

  const fetchReminderDetails = async (reminderId) => {
    try {
      const detailsData = await fetchWithRetry(`${process.env.REACT_APP_API_URL}/api/reminders/${reminderId}/details`);
      setReminders(prev => prev.map(r => r.id === reminderId ? { ...r, ...detailsData } : r));
    } catch (error) {
      console.error(`Error fetching details for reminder ${reminderId}:`, error);
    }
  };

  const fetchUserCredits = useCallback(async () => {
    if (authenticated && userId) {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/credits?userId=${userId}`);
        const data = await response.json();
        setCredits(data.credits);
        return data.credits;
      } catch (error) {
        console.error('Error fetching user credits:', error);
      }
    }
  }, [authenticated, userId]);

  const startPollingCredits = useCallback(() => {
    setIsPollingCredits(true);
    const pollCredits = async () => {
      const updatedCredits = await fetchUserCredits();
      if (updatedCredits > credits) {
        setIsPollingCredits(false);
        clearTimeout(pollingTimeoutRef.current);
        toast({
          title: "Credits Updated",
          description: `Your new balance is ${updatedCredits} credits.`,
          duration: 5000,
        });
      } else if (isPollingCredits) {
        pollingTimeoutRef.current = setTimeout(pollCredits, 2000); // Poll every 2 seconds
      }
    };
    pollCredits();

    // Stop polling after 2 minutes if credits haven't updated
    setTimeout(() => {
      setIsPollingCredits(false);
      clearTimeout(pollingTimeoutRef.current);
    }, 120000);
  }, [fetchUserCredits, credits, isPollingCredits]);

  useEffect(() => {
    return () => {
      if (pollingTimeoutRef.current) {
        clearTimeout(pollingTimeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (authenticated && userId) {
      fetchUserCredits();
      const intervalId = setInterval(fetchUserCredits, 10000); // Poll every 10 seconds
      return () => clearInterval(intervalId);
    }
  }, [authenticated, userId, fetchUserCredits]);

  // Debounced function to fetch profile info
  const debouncedFetchProfileInfo = useMemo(
    () => debounce(async (profile) => {
      if (!profile) return;
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/profile-info?profile=${encodeURIComponent(profile)}`);
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
        const data = await response.json();
        setProfileInfo(data);
      } catch (error) {
        console.error('Error fetching profile info:', error);
        setProfileInfo(null);
      }
    }, 1500),
    []
  );

  // Effect to call debounced function when input changes
  useEffect(() => {
    if (inputProfileToTrack) {
      debouncedFetchProfileInfo(inputProfileToTrack);
    } else {
      setProfileInfo(null);
    }
    return () => debouncedFetchProfileInfo.cancel();
  }, [inputProfileToTrack, debouncedFetchProfileInfo]);

  const handleProfileSearch = async (query) => {
    if (query.length < 3) return;
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/profile-info?profile=${encodeURIComponent(query)}`);
      const data = await response.json();
      setProfileInfo(data);
    } catch (error) {
      console.error('Error fetching profile info:', error);
      setProfileInfo(null);
    }
  };

  const addProfile = (profile) => {
    if (selectedProfiles.length < 5 && !selectedProfiles.some(p => p.handle === `@${profile.username}`)) {
      setSelectedProfiles([...selectedProfiles, {
        handle: `@${profile.username}`,
        displayName: profile.displayName,
        profileImageUrl: profile.profileImageUrl
      }]);
      setInputProfileToTrack('');
      setProfileInfo(null);
    }
  };

  const removeProfile = (index) => {
    setSelectedProfiles(selectedProfiles.filter((_, i) => i !== index));
  };

  const addOrUpdateReminder = async () => {
    if (!authenticated) {
      toast({
        title: "Authentication Required",
        description: "Please log in to add or update reminders.",
        variant: "destructive",
      });
      return;
    }

    setIsAddingReminder(true);

    const reminderData = {
      description: inputDescription,
      profiles: selectedProfiles,
      frequency,
      userId,
      includeReplies: includeReplies === "posts-and-replies"
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/reminders${editingReminder ? `/${editingReminder}` : ''}`,
        {
          method: editingReminder ? 'PUT' : 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(reminderData),
        }
      );

      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      const newReminder = await response.json();

      setReminders(prev => 
        editingReminder 
          ? prev.map(r => r.id === editingReminder ? newReminder : r)
          : [newReminder, ...prev]
      );

      toast({
        title: editingReminder ? "Reminder Updated" : "Reminder Added",
        description: editingReminder ? "Your reminder has been updated successfully." : "Your new reminder has been added successfully.",
      });

      if (!editingReminder && !isPushEnabled) {
        setShowPushDialog(true);
      }

      setSelectedProfiles([]);
      setInputDescription('');
      setFrequency('Weekly');
      setEditingReminder(null);
    } catch (error) {
      console.error('Error adding/updating reminder:', error);
      toast({
        title: "Error",
        description: `Failed to ${editingReminder ? 'update' : 'add'} reminder. Please try again.`,
        variant: "destructive",
      });
    } finally {
      setIsAddingReminder(false);
    }
  };

  // Use useCallback for event handlers
  const handleProfileToTrackChange = useCallback((e) => {
    const value = e.target.value;
    setInputProfileToTrack(value);
    setDebouncedProfileToTrack(value);
  }, []);

  const handleIncludeRepliesChange = useCallback((value) => {
    setIncludeReplies(value);
  }, []);

  const handleDescriptionChange = useCallback((e) => {
    setInputDescription(e.target.value);
  }, []);

  const handleEmailChange = useCallback((e) => {
    setInputEmail(e.target.value);
  }, []);

  useEffect(() => {
    if (authenticated && userId) {
      fetchUserCredits(userId).catch(console.error);
    }
  }, [authenticated, userId]);

  useEffect(() => {
    if (authenticated && userId && isPendingTransaction) {
      const currentTime = new Date().getTime();
      const transactionStartTime = new Date(pendingTransactionStartTime).getTime();
      const timeDifference = currentTime - transactionStartTime;

      if (timeDifference < 600000) { // 10 minutes in milliseconds
        // Instead of calling pollForCreditUpdates, we'll use the periodic credit check
        // that we've already implemented
        fetchUserCredits(userId).catch(console.error);
      } else {
        // If more than 10 minutes have passed, assume the transaction failed
        setIsPendingTransaction(false);
        setPendingTransactionStartTime(null);
        toast({
          title: "Transaction Timeout",
          description: "The previous transaction has timed out. Please try again or contact support if the issue persists.",
          variant: "destructive",
          duration: 10000,
        });
      }
    }
  }, [authenticated, userId, isPendingTransaction, pendingTransactionStartTime, setIsPendingTransaction, setPendingTransactionStartTime, toast]);

  const handleBuyCredits = async () => {
    if (!authenticated) {
      toast({
        title: "Authentication Required",
        description: "Please log in to buy credits.",
        variant: "destructive",
      });
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/create-charge`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          amount: usdcAmount,
          userId: userId,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      
      toast({
        title: "Checking for payment",
        description: "Your payment is being processed. Credits will be added to your account shortly.",
        duration: 5000,
      });

      // Open the Coinbase Commerce checkout page in a new window
      window.open(data.hostedUrl, '_blank', 'noopener,noreferrer');

      // Start polling for credit updates
      startPollingCredits();

    } catch (error) {
      console.error('Error creating charge:', error);
      toast({
        title: "Error",
        description: "Failed to create payment. Please try again.",
        variant: "destructive",
      });
    }

    setShowBuyCreditsDialog(false);
  };

  const handleBuyWithStripe = async () => {
    if (!authenticated) {
      toast({
        title: "Authentication Required",
        description: "Please log in to buy credits.",
        variant: "destructive",
      });
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/create-payment-intent`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          amount: usdcAmount,
          userId: userId,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setClientSecret(data.clientSecret);
      setShowStripeDialog(true);
    } catch (error) {
      console.error('Error creating payment intent:', error);
      toast({
        title: "Error",
        description: "Failed to initiate payment. Please try again.",
        variant: "destructive",
      });
    }
  };

  // Modify the checkUpdates function
  const checkUpdates = async (reminderId) => {
    if (credits < 10) {
      toast({
        title: "Insufficient Credits",
        description: "You need at least 10 credits to check for updates.",
        variant: "destructive",
      });
      return;
    }
  
    setCheckingReminders(prev => ({ ...prev, [reminderId]: true }));
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/check-updates/${reminderId}`);
      
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        const data = await response.json();
        console.log('Check updates response data:', data);
  
        if (response.status === 429) {
          toast({
            title: "Rate Limit Exceeded",
            description: data.message || "You're checking too fast! Please wait a few minutes before trying again.",
            duration: 5000,
          });
          return;
        }
  
        if (!response.ok) {
          throw new Error(data.error || `HTTP error! status: ${response.status}`);
        }
  
        if (data.reminder) {
          setReminders(prevReminders => 
            prevReminders.map(reminder => 
              reminder.id === reminderId 
                ? {
                    ...reminder,
                    lastChecked: data.reminder.lastChecked,
                    triggerEvents: data.found 
                      ? [...(data.reminder.triggerEvents || []), ...(reminder.triggerEvents || [])]
                      : reminder.triggerEvents
                  }
                : reminder
            )
          );
  
          if (data.found) {
            toast({
              title: "Truffle found!",
              description: "Check the reminder for details.",
              duration: 5000,
            });
            // Add this line to update profile cards
            await updateProfileCards();
          } else {
            toast({
              title: "No new truffle found",
              description: data.explanation || "We'll keep checking for you.",
              duration: 3000,
            });
          }
        }
  
        // Update credits immediately after a successful check
        if (data.credits !== undefined) {
          setCredits(data.credits);
        } else {
          // If credits are not returned in the response, fetch them separately
          const updatedCredits = await fetchUserCredits(userId);
          setCredits(updatedCredits);
        }
      } else {
        // If the response is not JSON, it's likely an error page
        const text = await response.text();
        console.error('Received non-JSON response:', text);
        throw new Error('Received non-JSON response from server. Please try again later.');
      }
    } catch (error) {
      console.error('Error checking updates:', error);
      toast({
        title: "Error checking updates",
        description: error.message || "Please try again later.",
        variant: "destructive",
      });
    } finally {
      setCheckingReminders(prev => ({ ...prev, [reminderId]: false }));
    }
  };
  const handleLogout = () => {
    safeLogout();
    setReminders([]); // Clear reminders on logout
    toast({
      title: "Logged Out",
      description: "You have successfully logged out.",
      duration: 3000,
    });
  };

  useEffect(() => {
    console.log('Profile image URL updated:', profileImageUrl);
  }, [profileImageUrl]);

  useEffect(() => {
    console.log('Frequency state updated:', frequency);
  }, [frequency]);

  const handleEnablePushNotifications = async () => {
    if (!('serviceWorker' in navigator) || !('PushManager' in window)) {
      toast({
        title: "Push Not Supported",
        description: "Push notifications are not supported in your browser.",
        variant: "destructive",
      });
      return;
    }

    try {
      const registration = await navigator.serviceWorker.register('/service-worker.js');
      console.log('Service Worker registered');

      // Wait for the service worker to be ready
      await navigator.serviceWorker.ready;
      console.log('Service Worker is ready');

      let subscription = await registration.pushManager.getSubscription();
      if (!subscription) {
        subscription = await registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: urlBase64ToUint8Array(process.env.REACT_APP_VAPID_PUBLIC_KEY),
        });
      }

      console.log('Push notification subscription:', subscription);

      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/save-subscription`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          subscription: subscription,
          userId: userId,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to save subscription on server');
      }

      setIsPushEnabled(true);
      toast({
        title: "Push Notifications Enabled",
        description: "You'll now receive notifications when truffles are found.",
        duration: 5000,
      });

      console.log('Push enabled state:', true);
    } catch (error) {
      console.error('Error enabling push notifications:', error);
      toast({
        title: "Error",
        description: "Failed to enable push notifications. Please try again.",
        variant: "destructive",
      });
    }
  };

  const handleEnablePush = async () => {
    await handleEnablePushNotifications();
    setShowPushDialog(false);
  };

  const editReminder = (id) => {
    const reminderToEdit = reminders.find(r => r.id === id);
    if (reminderToEdit) {
      setEditingReminder(id);
      setInputDescription(reminderToEdit.description);
      setSelectedProfiles(reminderToEdit.profiles || []);
      setFrequency(reminderToEdit.frequency);
      setIncludeReplies(reminderToEdit.includeReplies ? "posts-and-replies" : "only-posts");
      
      if (window.innerWidth < 1024) {
        setIsDrawerOpen(true);
        setIsEditingInDrawer(true);
      }
    }
  };

  const deleteReminder = async (id) => {
    // Optimistically remove the reminder from the UI
    const reminderToDelete = reminders.find(reminder => reminder.id === id);
    setReminders(prevReminders => prevReminders.filter(reminder => reminder.id !== id));

    // Show an optimistic toast
    toast({
      title: "Tracker Deleted",
      description: "Truffle dogs, stand down!",
    });

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/reminders/${id}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        if (response.status === 404) {
          // The reminder was already deleted
          toast({
            title: "Tracker Already Deleted",
            description: "This tracker was already removed.",
          });
        } else {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
      }

      // If the deletion was successful on the server, we don't need to do anything else
      // as we've already updated the UI optimistically
    } catch (error) {
      console.error('Error deleting tracker:', error);
      
      // If there was an error, revert the optimistic update
      setReminders(prevReminders => [...prevReminders, reminderToDelete]);
      
      // Show an error toast
      toast({
        title: "Error",
        description: "Failed to delete tracker. Please try again.",
        variant: "destructive",
      });
    }
  };

  const toggleReminderActive = async (id, currentStatus) => {
    // Optimistically update the UI
    setReminders(prevReminders => 
      prevReminders.map(reminder => 
        reminder.id === id ? { ...reminder, isActive: !currentStatus } : reminder
      )
    );

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/reminders/${id}/toggle`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ isActive: !currentStatus }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const updatedReminder = await response.json();
      
      // Update the reminder in the state with the server response
      setReminders(prevReminders => 
        prevReminders.map(reminder => 
          reminder.id === id ? { ...reminder, ...updatedReminder } : reminder
        )
      );

      toast({
        title: updatedReminder.isActive ? "Reminder Activated" : "Reminder Deactivated",
        description: `Your reminder has been ${updatedReminder.isActive ? 'activated' : 'deactivated'} successfully.`,
      });
    } catch (error) {
      console.error('Error toggling reminder:', error);
      
      // Revert the optimistic update if there was an error
      setReminders(prevReminders => 
        prevReminders.map(reminder => 
          reminder.id === id ? { ...reminder, isActive: currentStatus } : reminder
        )
      );
      
      toast({
        title: "Error",
        description: "Failed to update reminder status. Please try again.",
        variant: "destructive",
      });
    }
  };

  const renderCreditWarning = () => {
    if (credits <= 0) {
      return (
        <div className={`p-4 mb-6 rounded-md ${isDarkMode ? 'bg-red-900 text-red-100' : 'bg-red-100 text-red-800'} flex items-center`}>
          <AlertTriangle className="mr-2 h-6 w-6" />
          <span className="text-lg font-semibold">Not enough credits to track Truffle. Please buy some more.</span>
        </div>
      );
    }
    return null;
  };

  const fetchProfiles = useCallback(() => {
    if (authenticated && userId && Object.keys(profileData).length === 0) {
      fetchProfileData(userId);
    }
  }, [authenticated, userId, fetchProfileData, profileData]);

  useEffect(() => {
    fetchProfiles();
  }, [fetchProfiles]);

  const renderProfileCards = () => {
    if (!authenticated) {
      return <ExampleProfiles />;
    }

    if (!profileCards || Object.keys(profileCards).length === 0) return null;
    return (
      <div className="w-full overflow-x-auto mb-6">
        <div className="flex space-x-4 p-4 min-w-max">
          {Object.values(profileCards).map((profile) => (
            <ProfileCard
              key={profile.id}
              profileId={profile.id}
              profile={profile}
              tweets={profile.tweets || []}
              insights={profile.insights || []}
              isExample={false}
            />
          ))}
        </div>
      </div>
    );
  };

  useEffect(() => {
    const fetchProfileCards = async () => {
      if (authenticated && userId) {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/api/profile-cards?userId=${userId}`);
          if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
          const data = await response.json();
          console.log('Fetched profile cards:', data);
          setProfileCards(data);
        } catch (error) {
          console.error('Error fetching profile cards:', error);
          toast({
            title: "Error",
            description: "Failed to fetch profile cards. Please try again.",
            variant: "destructive",
          });
        }
      }
    };
  
    fetchProfileCards();
  }, [authenticated, userId]);

  const sortedReminders = useMemo(() => {
    return reminders.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  }, [reminders]);

  const calculateMonthlyCreditSpend = useMemo(() => {
    return reminders.reduce((total, reminder) => {
      const checksPerMonth = {
        'Hourly': 24 * 30,
        'Daily': 30,
        'Weekly': 4,
        'Monthly': 1
      };
      const profileCount = reminder.profiles ? reminder.profiles.length : 1;
      return total + (checksPerMonth[reminder.frequency] || 0) * 10 * profileCount;
    }, 0);
  }, [reminders]);

  const updateProfileCards = useCallback(async () => {
    if (authenticated && userId) {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/profile-cards?userId=${userId}`);
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
        const data = await response.json();
        console.log('Fetched profile cards:', data);
        setProfileCards(data);
      } catch (error) {
        console.error('Error fetching profile cards:', error);
        toast({
          title: "Error",
          description: "Failed to fetch profile cards. Please try again.",
          variant: "destructive",
        });
      }
    }
  }, [authenticated, userId, toast]);

  // Update this useEffect to use the new updateProfileCards function
  useEffect(() => {
    updateProfileCards();
  }, [updateProfileCards]);

  const renderCards = () => {
    if (!authenticated) {
      return <ExampleReminders onSignIn={safeLogin} isDarkMode={isDarkMode} />;
    }
    
    if (sortedReminders.length === 0) {
      return (
        <div className="flex flex-col items-center justify-center flex-grow py-12 text-center">
          <h3 className="text-xl font-semibold mb-2">No trackers yet</h3>
          <p className="text-muted-foreground">Create your first tracker to start finding truffles!</p>
          <p className="text-muted-foreground">For example, "let me know when my competitor launches a new feature" or "let me know when they tweet something that could make a good blog post"</p>
        </div>
      );
    }

    return sortedReminders.map((item) => (
      <div
        key={item.id}
        className={`transition-all duration-500 ease-out transform ${
          showContent ? 'translate-y-0 opacity-100' : 'translate-y-4 opacity-0'
        } mb-6`}
      >
        <ReminderItem
          reminder={item}
          onCheck={() => checkUpdates(item.id)}
          onEdit={() => editReminder(item.id)}
          onDelete={() => deleteReminder(item.id)}
          onToggle={() => toggleReminderActive(item.id, item.isActive)}
          onGenerateInsight={() => handleGenerateInsight(item.id)}
          isDarkMode={isDarkMode}
          isChecking={checkingReminders[item.id]}
          isGeneratingInsight={generatingInsights[item.id]}
          onAddProfile={handleAddProfile}
        />
      </div>
    ));
  };

  const renderContent = () => {
    return (
      <div className="flex-grow flex flex-col space-y-6 mb-12">
        <div className={`transition-all duration-500 ease-out ${isLoading ? 'opacity-100' : 'opacity-0 h-0 overflow-hidden'}`}>
          <SkeletonLoader isDarkMode={isDarkMode} />
        </div>
        <div className={`transition-all duration-500 ease-out ${showContent ? 'opacity-100' : 'opacity-0 h-0 overflow-hidden'}`}>
          {authenticated ? (
            <>
              {renderCreditWarning()}
              <div className="mb-6 -mt-16 relative z-10">
                <CreditsWidget 
                  credits={credits}
                  monthlySpend={calculateMonthlyCreditSpend}
                  onBuyCredits={() => setShowBuyCreditsDialog(true)}
                  isPendingTransaction={isPendingTransaction}
                  isDarkMode={isDarkMode}
                  onRefresh={refreshCredits}
                />
              </div>
              {renderProfileCards()}
              <div className="space-y-6">
                {renderCards()}
              </div>
            </>
          ) : (
            <>
              <ExampleProfiles />
              <ExampleReminders onSignIn={safeLogin} isDarkMode={isDarkMode} />
            </>
          )}
        </div>
      </div>
    );
  };

  const handleUsdcAmountChange = (e) => {
    const value = e.target.value;
    if (value === '' || isNaN(value)) {
      setUsdcAmount('');
    } else {
      setUsdcAmount(Math.max(1, Math.floor(parseFloat(value))));
    }
  };

  const handleAddProfile = (profile) => {
  if (selectedProfiles.length < 5 && !selectedProfiles.some(p => p.handle === profile.handle)) {
    setSelectedProfiles([...selectedProfiles, profile]);
    if (window.innerWidth < 1024) {
      setIsDrawerOpen(true);
    }
    toast({
      title: "Profile Added",
      description: `${profile.displayName || profile.handle} added to tracking list.`,
      duration: 3000,
    });
  } else if (selectedProfiles.length >= 5) {
    toast({
      title: "Maximum Profiles Reached",
      description: "You can track up to 5 profiles at once.",
      variant: "destructive",
      duration: 3000,
    });
  } else {
    toast({
      title: "Profile Already Added",
      description: `${profile.displayName || profile.handle} is already in the tracking list.`,
      duration: 3000,
    });
  }
};

  const renderSidebarContent = () => (
    <div className="flex flex-col h-full">
      <div className="flex-grow space-y-4 overflow-y-auto pr-4">
        <div className="space-y-2">
          <Label htmlFor="profile" className="block">Enter X (Twitter) to track</Label>
          <div className="relative w-full pl-1">
            <Input 
              id="profile" 
              placeholder="@username or URL"
              value={inputProfileToTrack}
              onChange={handleProfileToTrackChange}
              className="w-full"
            />
          </div>
          {profileInfo && !selectedProfiles.some(p => p.handle === `@${profileInfo.username}`) && (
            <div className="mt-2">
              <div className="flex items-center justify-between">
                <TwitterHandle 
                  handle={`@${profileInfo.username}`}
                  displayName={profileInfo.displayName}
                  profileImageUrl={profileInfo.profileImageUrl}
                  showRemove={false}
                />
                <Button 
                  onClick={() => {
                    addProfile(profileInfo);
                    setInputProfileToTrack('');
                    setProfileInfo(null);
                  }} 
                  disabled={selectedProfiles.length >= 5}
                  className="ml-2 bg-dark-green-gradient from-darkGreen-start to-darkGreen-end text-white"
                  size="sm"
                >
                  <Plus className="h-4 w-4 mr-1" />
                  <span className="text-xs">Track</span>
                </Button>
              </div>
            </div>
          )}
        </div>

        {selectedProfiles.length > 0 && (
          <>
            <p className="text-sm font-semibold text-gray-500 dark:text-gray-400">To track:</p>
            <div className="space-y-2">
              {selectedProfiles.map((profile, index) => (
                <div key={index} className="flex items-center justify-between">
                  <TwitterHandle
                    handle={profile.handle}
                    displayName={profile.displayName}
                    profileImageUrl={profile.profileImageUrl}
                    showRemove={false}
                  />
                  <Button
                    onClick={() => removeProfile(index)}
                    variant="ghost"
                    size="sm"
                    className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                  >
                    <X className="h-4 w-4" />
                  </Button>
                </div>
              ))}
            </div>
            <p className="text-xs text-gray-500 dark:text-gray-400">You can track up to 5 profiles at once!</p>
          </>
        )}

      <div className="space-y-2">
        <Label htmlFor="description" className="block">Let me know...</Label>
        <div className="pl-1">
          <Input 
            id="description" 
            placeholder={currentPlaceholderRef.current}
            className="h-24 w-full"
            value={inputDescription}
            onChange={handleDescriptionChange}
            multiline
          />
        </div>
      </div>

      <div className="space-y-2 mt-4">
      <Label htmlFor="includeReplies" className="block">Track</Label>
      <ToggleGroup
        type="single"
        id="includeReplies"
        value={includeReplies}
        onValueChange={handleIncludeRepliesChange}
        className="justify-start"
      >
        <ToggleGroupItem value="only-posts" aria-label="Only posts">
          Posts
        </ToggleGroupItem>
        <ToggleGroupItem value="posts-and-replies" aria-label="Posts and replies">
          Posts & replies
        </ToggleGroupItem>
      </ToggleGroup>
    </div>

      <div>
        <Label>Check every</Label>
        <div className="mt-1 w-full sm:w-auto">
          <ToggleGroup
            type="single"
            value={frequency}
            onValueChange={setFrequency}
            className="justify-start"
          >
            <ToggleGroupItem value="Daily" aria-label="Daily">
              Daily
            </ToggleGroupItem>
            <ToggleGroupItem value="Weekly" aria-label="Weekly">
              Weekly
            </ToggleGroupItem>
            <ToggleGroupItem value="Monthly" aria-label="Monthly">
              Monthly
            </ToggleGroupItem>
          </ToggleGroup>
        </div>
      </div>

      <Button 
        className="w-full h-12"
        onClick={authenticated ? addOrUpdateReminder : safeLogin}
        disabled={isAddingReminder}
        variant="primary"
      >
        {authenticated 
          ? (isAddingReminder ? 'Unleashing the dogs...' : (editingReminder ? 'Update Tracker' : 'Set Tracker 🐶'))
          : 'Log in to Set Tracker'}
      </Button>

      {editingReminder && (
        <Button 
          className={`w-full mt-2 ${isDarkMode ? 'bg-gray-700 text-white hover:bg-gray-600' : 'bg-gray-200 text-gray-800 hover:bg-gray-300'}`}
          onClick={() => {
            setEditingReminder(null);
            setInputDescription("");
            setSelectedProfiles([]);
            setIsEditingInDrawer(false);
            if (window.innerWidth < 1024) {
              setIsDrawerOpen(false);
            }
          }}
        >
          Cancel Edit
        </Button>
      )}
    </div>

    {authenticated && user && user.id === process.env.REACT_APP_ADMIN_ID && (
      <Button
        onClick={toggleAdminDashboard}
        className="mt-4"
        variant="outline"
      >
        <BarChart className="h-4 w-4 mr-2" />
        Admin Dashboard
      </Button>
    )}
  </div>
);

const refreshCredits = async () => {
  const updatedCredits = await fetchUserCredits();
  toast({
    title: "Credits Refreshed",
    description: `Your current balance is ${updatedCredits} credits.`,
    duration: 3000,
  });
};

const handleUpdate = useCallback(() => {
  if (waitingWorker) {
    waitingWorker.postMessage({ type: 'SKIP_WAITING' });
  }
  window.location.reload();
}, [waitingWorker]);

const dismissUpdate = useCallback(() => {
  setNewVersionAvailable(false);
  setDismissedVersion(localStorage.getItem('appVersion'));
}, []);

const checkForUpdates = useCallback(async () => {
  if ('serviceWorker' in navigator) {
    try {
      const registration = await navigator.serviceWorker.getRegistration();
      if (registration) {
        await registration.update();
        if (registration.waiting) {
          setWaitingWorker(registration.waiting);
          setNewVersionAvailable(true);
        }
      }

      const response = await fetch('/version.json?t=' + new Date().getTime());
      const data = await response.json();
      const storedVersion = localStorage.getItem('appVersion');
      
      if (storedVersion && storedVersion !== data.version && data.version !== dismissedVersion) {
        setNewVersionAvailable(true);
        localStorage.setItem('appVersion', data.version);
      } else if (!storedVersion) {
        localStorage.setItem('appVersion', data.version);
      }
    } catch (error) {
      console.error('Error checking for updates:', error);
    }
  }
}, [dismissedVersion]);

useEffect(() => {
  checkForUpdates();
  const intervalId = setInterval(checkForUpdates, 60000); // Check every minute

  return () => clearInterval(intervalId);
}, [checkForUpdates]);

useEffect(() => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      console.log('New service worker activated, reloading page');
      window.location.reload();
    });
  }
}, []);

const toggleAdminDashboard = useCallback(() => {
  setShowAdminDashboard(prev => !prev);
}, []);

const handleGenerateInsight = async (reminderId) => {
  if (credits < 10) {
    toast({
      title: "Insufficient Credits",
      description: "You need at least 10 credits to generate an insight.",
      variant: "destructive",
    });
    return;
  }

  setGeneratingInsights(prev => ({ ...prev, [reminderId]: true }));

  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/generate-insight/${reminderId}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ userId }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
    }

    const data = await response.json();

    setReminders(prevReminders => 
      prevReminders.map(reminder => 
        reminder.id === reminderId 
          ? { 
              ...reminder, 
              insights: [
                { content: data.insight, createdAt: new Date().toISOString(), tweetCount: data.tweetCount, isNew: true },
                ...(reminder.insights || [])
              ]
            } 
          : reminder
      )
    );

    setCredits(prevCredits => prevCredits - 10);
    await updateProfileCards();

    toast({
      title: "Insight Generated",
      description: "Check the reminder for the new insight.",
      duration: 5000,
    });
  } catch (error) {
    console.error('Error generating insight:', error);
    toast({
      title: "Error",
      description: error.message || "Failed to generate insight. Please try again.",
      variant: "destructive",
    });
  } finally {
    setGeneratingInsights(prev => ({ ...prev, [reminderId]: false }));
  }
};

useEffect(() => {
  if (user) {
    console.log('Current user ID:', user.id);
    console.log('Admin ID:', process.env.REACT_APP_ADMIN_ID);
    console.log('Is admin:', user.id === process.env.REACT_APP_ADMIN_ID);
  }
}, [user]);

useEffect(() => {
  const savedShowSubtext = localStorage.getItem('showSubtext');
  if (savedShowSubtext !== null) {
    setShowSubtext(JSON.parse(savedShowSubtext));
  }
}, []);

const toggleSubtext = () => {
  const newShowSubtext = !showSubtext;
  setShowSubtext(newShowSubtext);
  localStorage.setItem('showSubtext', JSON.stringify(newShowSubtext));
};

useEffect(() => {
  const loadTimeout = setTimeout(() => {
    if (document.readyState !== 'complete') {
      console.log('App failed to load, forcing reload');
      window.location.reload();
    }
  }, 10000); // 10 seconds timeout

  return () => clearTimeout(loadTimeout);
}, []);

if (!mounted) {
  return null; // or return a loading spinner
}

return (
  <div className="min-h-screen flex flex-col bg-background">
    <Toaster position="top-right" />
    
    <div className="flex flex-col lg:flex-row flex-grow">
      {/* Sidebar for desktop */}
      <div className="w-full lg:w-96 bg-background text-foreground shadow-lg lg:fixed lg:left-0 lg:top-0 lg:bottom-0 dark:bg-gray-900 hidden lg:flex lg:flex-col">
        <div className="p-4 flex flex-col h-full">
          <div className="mb-6">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center">
                <img src="/truffle-logo.png" alt="Truffle Logo" className="w-10 h-10 mr-2" />
                <h1 className="text-3xl font-normal" style={{ 
                  fontFamily: 'DepartureMono, monospace',
                  color: '#B58E73'
                }}>Truffle</h1>
              </div>
              <div className="flex items-center space-x-2">
                {authState === 'authenticated' ? (
                  <Button 
                    onClick={safeLogout} 
                    variant="secondaryToggle" 
                    size="icon"
                    className="h-10 w-10"
                    title="Log Out"
                  >
                    <LogOut className="h-[1.2rem] w-[1.2rem]" />
                  </Button>
                ) : (
                  <Button 
                    onClick={safeLogin} 
                    variant="secondaryToggle"
                    className="h-10"
                  >
                    Log In
                  </Button>
                )}
                <Button 
                  onClick={toggleDarkMode} 
                  variant="secondaryToggle" 
                  size="icon"
                  className="h-10 w-10"
                  title={isDarkMode ? "Switch to Light Mode" : "Switch to Dark Mode"}
                >
                  {isDarkMode ? <Sun className="h-[1.2rem] w-[1.2rem]" /> : <Moon className="h-[1.2rem] w-[1.2rem]" />}
                </Button>
              </div>
            </div>
            {authenticated && !isPushEnabled && (
              <Button 
                onClick={handleEnablePushNotifications}
                size="sm"
                variant="secondaryToggle"
                className="h-10 w-full mb-4"
              >
                <Bell className="h-4 w-4 mr-2" />
                Enable Push
              </Button>
            )}
          </div>
          <div className="text-sm text-muted-foreground mb-4">
            {showSubtext ? (
              <div className="flex items-start justify-between">
                <p className="flex-grow mr-4">
                Truffle is a Twitter / X Social Listening app that analyses posts with AI, helping you find hidden patterns and insights. Enter up to 5 profiles at the same time, what you're looking for, and AI tracks them for you. Always double check, AI can make mistakes. Each check costs 10 credits. You can easily buy more.
                  <button 
                    onClick={toggleSubtext}
                    className="inline-flex items-center font-bold text-gray-800 dark:text-white ml-2"
                  >
                    Got it
                  </button>
                </p>
              </div>
            ) : (
              <button 
                onClick={toggleSubtext}
                className="font-bold text-gray-800 dark:text-white"
              >
                How does it work?
              </button>
            )}
          </div>
          <div className="flex-grow overflow-y-auto -mr-4"> {/* Negative right margin */}
            {renderSidebarContent()}
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-1 lg:ml-96 flex flex-col overflow-x-hidden"> {/* Added overflow-x-hidden here */}

        {/* Top bar for mobile */}
        <div className="lg:hidden flex flex-col p-2 sm:p-4 bg-background border-b border-border">
          <div className="flex items-center justify-between mb-2">
            <div className="flex items-center">
              <img src="/truffle-logo.png" alt="Truffle Logo" className="w-8 h-8 mr-2" />
              <h1 className="text-2xl font-normal" style={{ 
                fontFamily: 'DepartureMono, monospace',
                color: '#B58E73'
              }}>Truffle</h1>
            </div>
            <div className="flex items-center space-x-2">
              {authState === 'authenticated' ? (
                <Button 
                  onClick={safeLogout} 
                  variant="secondaryToggle" 
                  size="icon"
                  className="h-12 w-12 sm:h-10 sm:w-10" // Increased size for mobile
                  title="Log Out"
                >
                  <LogOut className="h-6 w-6 sm:h-5 sm:w-5" /> {/* Increased icon size for mobile */}
                </Button>
              ) : (
                <Button 
                  onClick={safeLogin} 
                  variant="secondaryToggle"
                  size="sm"
                  className="h-12 sm:h-10 px-4 sm:px-3" // Increased height and padding for mobile
                >
                  Log In
                </Button>
              )}
              <Button 
                onClick={toggleDarkMode} 
                variant="secondaryToggle" 
                size="icon"
                className="h-12 w-12 sm:h-10 sm:w-10" // Increased size for mobile
                title={isDarkMode ? "Switch to Light Mode" : "Switch to Dark Mode"}
              >
                {isDarkMode ? <Sun className="h-6 w-6 sm:h-5 sm:w-5" /> : <Moon className="h-6 w-6 sm:h-5 sm:w-5" />} {/* Increased icon size for mobile */}
              </Button>
            </div>
          </div>
          {!authenticated && (
            <div className="text-sm text-muted-foreground mt-2">
              {showSubtext ? (
                <p>
                  Truffle is a Twitter / X Social Listening app that analyses posts with AI, helping you find hidden patterns and insights. Enter up to 5 profiles at the same time, what you're looking for, and AI tracks them for you. Always double check, AI can make mistakes. Each check costs 10 credits. You can easily buy more.
                  <button 
                    onClick={toggleSubtext}
                    className="font-bold text-gray-800 dark:text-white ml-2"
                  >
                    Got it
                  </button>
                </p>
              ) : (
                <button 
                  onClick={toggleSubtext}
                  className="font-bold text-gray-800 dark:text-white"
                >
                  How does it work?
                </button>
              )}
            </div>
          )}
        </div>

        {/* Update and Offline banners */}
        <div className="lg:sticky lg:top-0 z-50">
          {newVersionAvailable && (
            <div className="bg-blue-500 text-white p-4 flex items-center justify-center" role="alert">
              <p className="font-bold mr-4">Updates, fresh out of the oven🎉</p>
              <button 
                onClick={handleUpdate}
                className="bg-white text-blue-500 px-4 py-2 rounded hover:bg-blue-100 transition-colors"
              >
                Update Now
              </button>
            </div>
          )}
          
          {!isOnline && (
            <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4" role="alert">
              <p className="font-bold">You are offline</p>
              <p>Some features may be unavailable until you reconnect.</p>
            </div>
          )}
        </div>

        {/* Banner with Title */}
        <div className="w-full h-48 bg-cover bg-center relative" style={{ backgroundImage: "url('/truffle-banner.png')" }}>
          <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center px-4 sm:px-6">
            <h2 className="text-3xl font-semibold text-white" style={{ fontFamily: 'DepartureMono, monospace' }}>
              {authenticated ? 'Your Trackers 🐕' : 'Example Trackers'}
            </h2>
          </div>
        </div>

          {/* Reminders Section */}
          <div className="flex-grow flex flex-col items-center">
            <div className="w-full max-w-4xl px-4 sm:px-6 lg:px-8">
              {renderContent()}
            </div>

            {/* Footer */}
            <footer className="w-full bg-background text-foreground border-t border-border bg-cover bg-center relative transition-all duration-500 ease-out" 
                    style={{ backgroundImage: "url('/truffle-banner.png')", opacity: showContent ? 1 : 0 }}>
              <div className="absolute inset-0 bg-black bg-opacity-70"></div>
              <div className="relative z-10 p-10 sm:p-10 flex flex-col sm:flex-auto items-center">
                <a 
                  href="https://x.com/dmnc_eu" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="text-sm text-gray-300 hover:text-white transition-colors text-center sm:text-center"
                >
                  Built by Dominic Emanuel Horn & AI.<br></br> 
                  Message me on X ↗
                </a>
              </div>
            </footer>
          </div>
        </div>
      </div>

      {/* Floating button for mobile */}
      <div className="lg:hidden fixed bottom-4 right-4 z-50">
    <Sheet open={isDrawerOpen} onOpenChange={(open) => {
      setIsDrawerOpen(open);
      if (!open) {
        setIsEditingInDrawer(false);
        setEditingReminder(null);
      }
    }}>
      <SheetTrigger asChild>
        <Button 
          className="rounded-full w-16 h-16 shadow-lg bg-dark-green-gradient from-darkGreen-start to-darkGreen-end text-white hover:brightness-110" 
          onClick={() => setIsDrawerOpen(true)}
        >
          <Plus className="h-6 w-6" />
        </Button>
      </SheetTrigger>
      <SheetContent side="bottom" className="h-[80vh] flex flex-col p-0">
        <SheetHeader className="flex justify-between items-center p-4 border-b">
          <SheetTitle>{isEditingInDrawer ? 'Edit tracker 🐾' : 'Set up tracker 🐾'}</SheetTitle>
          <SheetClose asChild>
            <Button variant="ghost" size="icon">
              <X className="h-4 w-4" />
            </Button>
          </SheetClose>
        </SheetHeader>
        <div className="flex-grow overflow-y-auto p-4">
          {renderSidebarContent()}
        </div>
      </SheetContent>
    </Sheet>
  </div>

      {/* Push Notification Dialog */}
      <Dialog open={showPushDialog} onOpenChange={setShowPushDialog}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>Enable Push Notifications</DialogTitle>
            <DialogDescription>
              Get notified instantly when we find a truffle for you! 🍄‍🟫
            </DialogDescription>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <p>
              Stay updated with real-time alerts for your trackers. Never miss an important update!
            </p>
          </div>
          <DialogFooter>
            <Button
              onClick={() => setShowPushDialog(false)}
              variant="outline"
              className="w-full sm:w-auto"
            >
              Maybe Later
            </Button>
            <Button
              onClick={handleEnablePush}
              className="w-full sm:w-auto bg-dark-green-gradient from-darkGreen-start to-darkGreen-end text-white"
            >
              Enable Push Notifications
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <Dialog open={showBuyCreditsDialog} onOpenChange={setShowBuyCreditsDialog}>
        <DialogContent className="sm:max-w-[425px] pb-12 sm:pb-6">
          <DialogHeader>
            <DialogTitle className="text-2xl font-bold" style={{ fontFamily: 'DepartureMono, monospace' }}>Get Credits</DialogTitle>
            <DialogDescription className="text-lg">
              1$ = {formatNumber(100)} Credits = 10 checks
            </DialogDescription>
          </DialogHeader>
          <div className="mt-4 mb-8 space-y-4">
            <div className="relative">
              <Input
                type="number"
                inputMode="decimal"
                value={usdcAmount}
                onChange={handleUsdcAmountChange}
                className="text-3xl pr-32 font-mono"
                style={{ fontFamily: 'DepartureMono, monospace' }}
              />
              <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 text-xl">
                $
              </span>
            </div>
            <div className="flex justify-between text-xl" style={{ fontFamily: 'DepartureMono, monospace' }}>
              <span className="text-gray-500">
                {usdcAmount ? formatNumber(usdcAmount * 100) : 0}
              </span>
              <span className="text-gray-500">
                Credits
              </span>
            </div>
          </div>
          <DialogFooter className="sm:justify-start flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2">
            <Button 
              onClick={handleBuyCredits} 
              className="w-full h-12 mb-2 flex items-center justify-center"
              variant="primary"
            >
              <img src={coinbaseLogo} alt="Coinbase Logo" className="h-6 mr-2" />
              Crypto Checkout
            </Button>
            <Button 
              onClick={handleBuyWithStripe} 
              className="w-full h-12 flex items-center justify-center"
              variant="outline"
            >
              <img src={stripeLogo} alt="Stripe Logo" className="h-6 mr-2" />
              Stripe Checkout
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <Dialog open={showStripeDialog} onOpenChange={setShowStripeDialog}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>Buy Credits with Card</DialogTitle>
          </DialogHeader>
          {clientSecret && (
            <Elements stripe={stripePromise} options={{ clientSecret }}>
              <StripeCheckoutForm 
                amount={usdcAmount} 
                onSuccess={() => {
                  setShowStripeDialog(false);
                  toast({
                    title: "Payment Successful",
                    description: "Your credits will be added shortly.",
                    duration: 5000,
                  });
                  startPollingCredits();
                }}
              />
            </Elements>
          )}
        </DialogContent>
      </Dialog>

      {/* Admin Dashboard Dialog */}
      <Dialog open={showAdminDashboard} onOpenChange={setShowAdminDashboard}>
        <DialogContent className="max-w-4xl h-[80vh] overflow-y-auto">
          <DialogHeader>
            <DialogTitle>Admin Dashboard</DialogTitle>
          </DialogHeader>
          <AdminDashboard />
        </DialogContent>
      </Dialog>
    </div>
  );
};

const ReminderSkeleton = () => (
  <div className="animate-pulse">
    <div className="h-4 bg-gray-200 rounded w-3/4 mb-2"></div>
    <div className="h-4 bg-gray-200 rounded w-1/2"></div>
  </div>
);

export default TruffleApp;