import React from 'react';
import { ThemeProvider } from './contexts/ThemeContext';
import { ProfileProvider } from './contexts/ProfileContext';
import TruffleApp from './components/TruffleApp';
import './globals.css';  // Make sure this path is correct

function App() {
  return (
    <ThemeProvider>
      <ProfileProvider>
        <TruffleApp />
      </ProfileProvider>
    </ThemeProvider>
  );
}

export default App;
