import React from 'react';
import { X, Plus } from 'lucide-react';

const TwitterHandle = ({ handle, displayName, profileImageUrl, onRemove, showRemove = true, isOnCard = false, onAdd }) => {
  const cleanHandle = handle ? handle.replace(/^@/, '') : '';
  return (
    <div className={`flex items-center space-x-2 mr-1 ${isOnCard ? '' : 'bg-gray-100 dark:bg-white/10'} rounded-full p-1`}>
      <a
        href={`https://x.com/${cleanHandle}`}
        target="_blank"
        rel="noopener noreferrer"
        className="flex items-center space-x-2 hover:opacity-80 active:opacity-60 active:scale-95 transition-all duration-150 w-full"
      >
        {profileImageUrl && (
          <img 
            src={profileImageUrl} 
            alt={`${displayName || handle || 'Profile'}'s profile`} 
            className="w-6 h-6 rounded-full flex-shrink-0 object-cover"
          />
        )}
        <div className="flex-grow min-w-0">
          <div className="flex flex-wrap items-center">
            <span className="text-sm mr-1 break-words">{displayName || handle || 'Unknown'}</span>
            {cleanHandle && <span className="text-xs text-gray-500 dark:text-gray-400 break-all">@{cleanHandle}</span>}
          </div>
        </div>
      </a>
      {showRemove && onRemove && (
        <button 
          onClick={onRemove} 
          className="ml-2 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
        >
          <X className="h-4 w-4" />
        </button>
      )}
      {isOnCard && onAdd && (
        <button 
          onClick={onAdd} 
          className="ml-2 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
          title="Add to tracking list"
        >
          <Plus className="h-4 w-4" />
        </button>
      )}
    </div>
  );
};

export default TwitterHandle;
